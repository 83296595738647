<template>
  <div class="window-titlebar" @click="onPlaylist">
    <!-- TEXT -->
    <span class="text">
      <!-- ICQ ICON -->
      <img v-show="title === 'Chat'" width="16" class="mr-1" src="./assets/img/online@2x.png" />

      <!-- YOUTUBE ICON -->
      <i v-show="title === 'Playlist'" class="fa fa-youtube-play mr-1"></i>

      <!-- SPAN -->
      <span>{{ title }}</span>
    </span>

    <!-- TITLEBAR BUTTONS -->
    <!-- <div class="window-titlebar-btns"> -->

    <!-- TOGGLE TRANSPARENCY -->
    <!-- <button
				class="window-titlebar-btn btn-toggle-transparency"
				@click.stop="onToggleTransparency()"
			>
				<i class="fa fa-eyedropper"></i>
			</button>
		</div> -->
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    methods: {
      onPlaylist() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "7855cc11-f031-4e0c-a321-a0f573eb0599" },
        ]); // chat playlist
      },
      onToggleTransparency() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "99219979-ff89-48a8-b026-71157c858fe4" },
        ]); // chat transparency
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------
  // WINDOW HEADER
  //--------------------------------------------------------

  .window-titlebar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem-calc(30);
    background: rgba(black, 0.6); // 0.4
    // background: linear-gradient(to top, rgba(black, 0.5) 00%, rgba(black, 0.4) 100%);
    font-size: rem-calc(14);
    font-weight: 600;
    text-transform: uppercase;
    color: #ccc;
    cursor: pointer;

    // -webkit-backdrop-filter: blur(6px);
    // backdrop-filter: blur(6px);
  }

  .window-titlebar .fa-youtube-play {
    color: #ff0000;
  }

  .window-titlebar-btns {
    position: absolute;
    left: 0;
    // right: 0;
  }

  // .is-admin .window-titlebar-btns {
  // 	display: block;
  // }

  .window-titlebar-btn {
    padding: 0 10px;
    border: 0;
    box-shadow: none;
    outline: none;
    background: none;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    color: #ccc;
    cursor: default;

    .fa {
      position: relative;
      top: -2px;
      font-size: rem-calc(17);
    }

    &:hover {
      background: rgba(black, 0.2);
    }
  }
</style>
